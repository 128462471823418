<template>
  <div class="pdf">
    <pdf
      v-for="currentPage in pageCount"
      :key="currentPage"
      :src="src"
      :page="currentPage"
    >
    </pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 5, // pdf文件总页数
      src: "static/防爆化工轮式巡检机器人-售后服务说明书.pdf", // pdf文件地址
    };
  },
  created() {
    this.src = pdf.createLoadingTask(this.src);
  },
  method: {
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf {
  width: 100%;
  height: 100%;
}
</style>
